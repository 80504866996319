import React from 'react'
import { themeColor } from '@edulastic/colors'
import SVG from './common/SVG'
import withIconStyles from './HOC/withIconStyles'

const IconPearAssessCertifiedLogo = (props) => (
  <SVG
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1683 3.09839C11.7052 1.41107 14.3401 1.34963 15.9539 2.96349L18.1556 5.16515C18.7005 5.7101 19.3913 6.08602 20.1448 6.24771L21.3506 6.50644C23.7719 7.02601 25.1441 9.59856 24.2269 11.8989L23.5811 13.5185C23.2018 14.4698 23.2018 15.5302 23.5811 16.4815L24.2269 18.1011C25.1441 20.4014 23.7719 22.974 21.3506 23.4936L20.1448 23.7523C19.3913 23.914 18.7005 24.2899 18.1556 24.8348L15.942 27.0485C14.3326 28.6578 11.7066 28.6019 10.1673 26.9255L8.36254 24.9602C7.78957 24.3362 7.03329 23.9103 6.20268 23.7437L4.70729 23.4439C2.25774 22.9527 0.852869 20.3611 1.77814 18.0405L2.39976 16.4814C2.77902 15.5302 2.77902 14.4698 2.39976 13.5186L1.69437 11.7494C0.797637 9.50034 2.08896 6.97652 4.43758 6.38795L6.80929 5.79359C7.57329 5.60213 8.26376 5.18938 8.79413 4.6071L10.1683 3.09839Z"
      fill={themeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9174 7.51172C11.2851 7.51172 10.8405 7.83305 10.5168 8.067C10.4708 8.10018 10.4274 8.13161 10.3861 8.16011C10.207 8.28392 10.0754 8.35459 9.94666 8.37595C9.83304 8.39481 9.68469 8.38065 9.46452 8.24683L8.98273 8.51779C8.98273 9.00677 9.26039 9.51103 9.66523 9.88281C10.0769 10.2609 10.655 10.5362 11.315 10.5362C11.805 10.5362 12.3416 10.3636 12.7942 10.051C12.803 10.1734 12.821 10.2973 12.8487 10.4219C12.3366 10.4594 11.8785 10.6204 11.4899 10.9162C11.0449 11.2549 10.7188 11.749 10.4976 12.3674C10.289 12.9504 9.99835 13.3991 9.68615 13.8811C9.56553 14.0673 9.44169 14.2584 9.31812 14.4642C8.88203 15.1906 8.51367 16.0174 8.51367 17.2444C8.51367 19.6391 10.4817 21.5117 13.0871 21.5117C15.6924 21.5117 17.6605 19.6391 17.6605 17.2444C17.6605 16.0174 17.2921 15.1906 16.856 14.4642C16.7325 14.2585 16.6087 14.0674 16.4881 13.8813L16.4881 13.8812L16.488 13.8811C16.1758 13.3991 15.8851 12.9504 15.6766 12.3674C15.4553 11.749 15.1293 11.2549 14.6842 10.9162C14.2942 10.6193 13.834 10.4582 13.3196 10.4215C13.226 9.24572 13.8027 8.63129 15.0318 8.63129L15.0729 7.51223C14.3384 7.51223 13.7007 7.86115 13.2869 8.39731C13.2264 8.2502 13.1446 8.11359 13.04 7.9929C12.775 7.68692 12.3881 7.51172 11.9174 7.51172ZM13.2992 17.1417C13.5748 17.1417 13.7982 16.9183 13.7982 16.6427C13.7982 16.3672 13.5748 16.1438 13.2992 16.1438C13.0236 16.1438 12.8002 16.3672 12.8002 16.6427C12.8002 16.9183 13.0236 17.1417 13.2992 17.1417ZM14.7355 17.1417C15.011 17.1417 15.2345 16.9183 15.2345 16.6427C15.2345 16.3672 15.011 16.1438 14.7355 16.1438C14.4599 16.1438 14.2365 16.3672 14.2365 16.6427C14.2365 16.9183 14.4599 17.1417 14.7355 17.1417ZM12.9606 17.7738C12.9606 18.3114 13.3799 18.765 14.0174 18.765C14.6549 18.765 15.0742 18.3114 15.0742 17.7738H15.8061C15.8061 18.7322 15.0424 19.4969 14.0174 19.4969C12.9924 19.4969 12.2286 18.7322 12.2286 17.7738H12.9606Z"
      fill="white"
    />
  </SVG>
)

export default withIconStyles(IconPearAssessCertifiedLogo)
